import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/monetary';

export default {
    get_debt_monetary(payload) {
        return axios.get(`${RESOURCE_NAME}/debt-monetary`, {params: {header_id: payload}})
    },
    get_pay_monetary(payload) {
        return axios.get(`${RESOURCE_NAME}/pay-monetary`, {params: {header_id: payload}})
    },
    save_debt_monetary(payload) {
        return axios.post(`${RESOURCE_NAME}/debt-monetary`, payload)
    },
    save_pay_monetary(payload) {
        return axios.post(`${RESOURCE_NAME}/pay-monetary`, payload)
    },
    remove_debt_monetary(payload) {
        return axios.post(`${RESOURCE_NAME}/debt-monetary/remove`, { header_id: payload })
    },
    remove_pay_monetary(payload) {
        return axios.post(`${RESOURCE_NAME}/pay-monetary/remove`, { header_id: payload })
    },
    get_monetary_debt_headers(payload) {
        return axios.get(`${RESOURCE_NAME}/debt-monetary-header`, {params: {month: payload}})
    },
    get_monetary_pay_headers(payload) {
        return axios.get(`${RESOURCE_NAME}/pay-monetary-header`, {params: {month: payload}})
    },
    importFile(payload) {
        return axios.post(`${RESOURCE_NAME}/import-file`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    importFilePay(payload) {
        return axios.post(`${RESOURCE_NAME}/import-file-pay`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
};